export const projects = [
  {
    name: "Kladi",
    logo: "https://avatars.githubusercontent.com/t/4917187?s=280&v=4",
    projectLink: "https://www.npmjs.com/package/kladi",
  },
  {
    name: "OpenSourcer",
    logo: "https://avatars.githubusercontent.com/t/4917187?s=280&v=4",
    projectLink: "https://github.com/Tech-Phantoms/opensourcer-client",
  },
];
